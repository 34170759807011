@use '@angular/material' as mat;
@import '~video.js/dist/video-js.css';
@import '~videojs-record/dist/css/videojs.record.css';

@import '~ngx-toastr/toastr';

.cmx-toast {
  display: flex;
  width: 100%;
  height: 100%;
  &.ngx-toastr {
    padding: 0;
  }
}

.toast-center-center {
  width: 100%;
  height: 100%;
}

@include mat.core();

$custom-typography: mat.define-typography-config(
  $font-family: 'Mulish,sans-serif',
);

@include mat.all-component-typographies($custom-typography);

/* Material Color Palette */

/* For use in src/lib/core/theming/_palette.scss */
$md-warn: (
  50: #f7e0e0,
  100: #ecb3b3,
  200: #e08080,
  300: #d34d4d,
  400: #c92626,
  500: #c00000,
  600: #ba0000,
  700: #b20000,
  800: #aa0000,
  900: #9c0000,
  A100: #ffc7c7,
  A200: #ff9494,
  A400: #ff6161,
  A700: #ff4747,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);
$md-accent: (
  50: #fff7e0,
  100: #ffecb3,
  200: #ffe080,
  300: #ffd34d,
  400: #ffc926,
  500: #ffc000,
  600: #ffba00,
  700: #ffb200,
  800: #ffaa00,
  900: #ff9c00,
  A100: #ffffff,
  A200: #fff9f2,
  A400: #ffe3bf,
  A700: #ffd8a6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-blue: (
  50: #e4eaef,
  100: #bccad7,
  200: #8fa7bc,
  300: #6283a1,
  400: #41698d,
  500: #1f4e79,
  600: #1b4771,
  700: #173d66,
  800: #12355c,
  900: #0a2549,
  A100: #80afff,
  A200: #4d8fff,
  A400: #1a6fff,
  A700: #005eff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
$md-success: (
  50: #eef6e9,
  100: #d4e8c7,
  200: #b8d9a2,
  300: #9bc97c,
  400: #85be60,
  500: #70b244,
  600: #68ab3e,
  700: #5da235,
  800: #53992d,
  900: #418a1f,
  A100: #d6ffc5,
  A200: #b2ff92,
  A400: #8eff5f,
  A700: #7cff46,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-disabled: (
  50: #efefef,
  100: #d7d7d7,
  200: #bcbdbd,
  300: #a1a2a2,
  400: #8c8e8e,
  500: #787a7a,
  600: #707272,
  700: #656767,
  800: #5b5d5d,
  900: #484a4a,
  A100: #b5f8f8,
  A200: #87f3f3,
  A400: #47ffff,
  A700: #2effff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$prodeo-primary: mat.define-palette($md-blue);
$prodeo-accent: mat.define-palette($md-accent);
$prodeo-success: mat.define-palette($md-success);
$prodeo-warn: mat.define-palette($md-warn);
$prodeo-disabled: mat.define-palette($md-disabled);

$prodeo-theme: mat.define-light-theme(
  (
    color: (
      'primary': $prodeo-primary,
      'accent': $prodeo-accent,
      'warn': $prodeo-warn,
      'disabled': $prodeo-disabled,
      'success': $prodeo-success,
    ),
  )
);
$primary: mat.get-color-from-palette($prodeo-primary);
$accent: mat.get-color-from-palette($prodeo-accent);
$warn: mat.get-color-from-palette($prodeo-warn);
$disabled: mat.get-color-from-palette($prodeo-disabled);
$success: mat.get-color-from-palette($prodeo-success);

$foreground: map-get($prodeo-theme, foreground);
$background: map-get($prodeo-theme, background);

$themes: (
  'primary': $primary,
  'accent': $accent,
  'warn': $warn,
  'success': $success,
  'disabled': $disabled,
);
@include mat.all-component-themes($prodeo-theme);

/*---------------------UTILITY STYLES STARTS FROM HERE---------------------------*/
*,
html,
body {
  box-sizing: border-box;
}
$no-of-columns: 12;
$no-of-values: 25;
$breakpoints: (
  'sm': 318px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
);
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-reverse {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.d-flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.videoStatus {
  max-width: 90vw !important;
  width: 90vh !important;
  height: 90vw !important;
  .mat-dialog-container {
    padding: 4px !important;
  }
}

.space-between {
  justify-content: space-between;
}

.tile-text {
  background: #6da8de;
  font-weight: 600;
  span {
    text-align: center;
    display: inline-block;
    width: 100%;
  }
}
$no-of-pixels: 300;

@mixin list-scroll-classes {
  @for $i from 1 through $no-of-pixels {
    .list-scroll-#{$i} {
      height: calc(100vh - #{$i}px);
      overflow: auto;
    }
  }
}
@include list-scroll-classes;
.selected-list .mat-card:active,
.selected-list .mat-card:hover {
  border: 1px solid #ffffff;
  background: #f7f7f7;
}
@each $breakpoint, $value in $breakpoints {
  @media only screen and (min-width: $value) {
    @for $i from 1 through $no-of-columns {
      .col-#{$breakpoint}-#{$i} {
        flex-basis: (100 / ($no-of-columns / $i)) * 1%;
      }
    }
  }
}
.text-bold {
  font-weight: bold;
}

.mat-dialog-container {
  .form-container {
    padding-bottom: 0;
  }
}
.position-relative-dropdown {
  position: relative;
  .form-container form {
    position: absolute;
    z-index: 150;
    background: #fff;
    width: 100%;
    box-shadow: 0px 5px 7px 0px #716c6c52;
    padding: 0 0 1rem 0;
  }
}
@media screen and (min-width: 712px) {
  .position-relative-dropdown .form-container form {
    .form-action-buttons {
      button {
        margin-right: 12px;
      }
    }
  }
}
.text-upper {
  text-transform: uppercase;
}
.task-manager {
  .sticky {
    position: sticky;
    top: 0;
    z-index: 9;
    &.sticky-bg-white {
      background-color: #fff;
    }
  }
}
.selected {
  mat-card,
  mat-card:hover,
  mat-card:active {
    background: #c7f2e7;
  }
}
.form-container,
.task-manager {
  width: 100%;
  .sticky {
    position: sticky;
    top: 0;
    z-index: 999;
  }
  .desktop-section-1 {
    width: 100%;
    max-width: 330px;
    margin: 0 3.5px;
    display: inline-flex;
    border: 1px solid #2e75b6;
    box-shadow: 1px 1px 5px #2e75b67d;
    vertical-align: top;
    .list-scroll {
      height: calc(100vh - 250px);
      max-height: calc(100vh - 250px);
      .mat-card {
        .mat-fab {
          display: none;
        }
      }
    }
  }
  .scroll-completed-task {
    height: calc(100vh - 48px);
    overflow: auto;
    .panel {
      height: auto;
    }
  }
  .desktop-hide {
    display: none;
  }
  .mobile-hide {
    display: block;
  }
  .desktop-detail-section {
    width: 100%;
    display: -webkit-inline-box;
    margin: 0 3.5px;
    vertical-align: top;

    height: calc(100vh - 100px);
    overflow: auto;
    .video-js .vjs-big-play-button {
      font-size: 2em !important;
      transform: translate(-50%, -50%);
      margin-top: 0;
      margin-left: 0;
    }
    .mat-card {
      .mat-card-content {
        margin: 5px auto;
        height: 100%;
      }

      border: 1px solid #1f4e79;
      box-shadow: 1px 1px 5px #2e75b67d;
      border-radius: 0;
      .mat-card {
        .card-content {
          padding: 0;
        }
      }
    }
    box-shadow: 1px 1px 5px #2e75b67d;
  }
  .milestone-wrapper {
    width: 100%;
    cmx-milestone {
      margin: 0 auto;
      text-align: center;
      width: 100%;
      max-width: 814px;
      overflow: auto;
    }
  }
  @media screen and (max-width: 712px) and (min-width: 319px) {
    .desktop-hide {
      display: block;
    }
    .mobile-hide {
      display: none;
    }
  }
  .mat-form-field-type-multicheckbox {
    .mat-form-field-wrapper {
      padding-bottom: 0;
      margin: 7px 0;
    }
    .mat-form-field-infix {
      margin-top: -12px !important;
      padding: 5px 0.8rem !important;
    }
    .mat-checkbox {
      display: block;
      margin-bottom: 4px;
    }
    .mat-checkbox-label {
      font-weight: bold;
      margin-left: 12px;
    }
  }
  .mat-form-field-type-radio {
    .mat-form-field-wrapper {
      padding-bottom: 0;
      margin: 7px 0;
    }
    .mat-form-field-infix {
      margin-top: -12px !important;
      padding: 5px 0.8rem !important;
    }
    .mat-radio-group,
    .mat-radio-button {
      display: block;
      margin-bottom: 4px;
    }
    .mat-radio-label {
      font-weight: bold;
    }
  }
  .mat-form-field-wrapper {
    padding-bottom: 0;
    margin: 7px 0;
  }
  .mat-form-field {
    width: 100%;
  }
  .mat-chip.mat-standard-chip {
    background-color: #f3f3f3;
    box-shadow: 1px 2px 6px 2px #bbb3b352;
    margin-bottom: 6px;
  }
  .mat-form-field-appearance-outline .mat-form-field-label {
    top: 1.4em;
  }
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    bottom: 0;
  }
  .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    padding: 0;
    margin-top: 2em;
  }
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-outline.mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: translateY(-15px) scale(0.75);
  }
  .mat-form-field-appearance-outline .mat-form-field-outline,
  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: #1f4e79;
  }
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 5px 0 10px 0;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-start,
  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0;
  }
  .mat-form-field:not(.mat-form-field-appearance-legacy) {
    .mat-form-field-prefix .mat-icon-button,
    .mat-form-field-suffix .mat-icon-button {
      vertical-align: revert;
    }
  }
  position: relative;
  // padding-bottom: 5.3rem;
  background: #fff;
  @each $theme, $value in $themes {
    .mat-raised-button.mat-#{$theme},
    .mat-button.mat-#{$theme} {
      background-color: $value;
      border-color: $value !important;
      .mat-icon,
      span {
        color: #fff;
      }
    }
    .mat-badge-#{$theme} {
      .mat-badge-content.mat-badge-active {
        background-color: $value;
      }
    }
    .#{$theme} {
      button {
        background-color: $value;
        border-color: $value !important;
        .mat-icon,
        span {
          color: #fff;
        }
      }
      .mat-card {
        background-color: $value;
        border: 1px solid $value;
        border-radius: 0;
        box-shadow: none;
        span {
          color: #fff;
        }
        .mat-button,
        .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
          background: #fff;
          color: $value;
        }
        button.mat-button.close-icon {
          background: transparent;
          .mat-icon {
            color: #fff;
          }
        }
        span,
        .mat-icon-no-color {
          color: #fff;
        }
      }

      span,
      .mat-icon-no-color {
        color: $value;
      }
    }
    .mat-card.#{$theme} {
      background: $value;
      .card-content,
      .mat-icon-no-color {
        color: #fff;
      }
      .counter-container {
        button {
          background-color: #fff;
          .mat-icon-no-color {
            color: $value;
          }
          span.mat-button-wrapper {
            color: $value;
          }
        }
        .counter {
          background: #fff;
          color: $value;
        }
      }
    }
  }
  .mat-accent,
  .accent button {
    .mat-icon,
    span {
      color: #333 !important;
        
    }
    span.mat-button-wrapper mat-icon {
      color: $accent !important;
    }
    
    .fil0 {
      fill: #333 !important;
    }
  }
  .accent {
    .mat-icon-no-color,
    .card-content,
    .counter {
      color: #333 !important;
    }
  }
  .stretch-none {
    text-align: center;
    button {
      width: auto;
      margin: auto;
    }
  }
  .text-left {
    button {
      text-align: left;
      padding-left: 1rem;
    }
  }
  //text-overflow-ellipses
  @mixin text-hidden-classes {
    @for $i from 1 through $no-of-values {
      $chars: $i * 4ch;
      .text-hidden-#{$i} {
        white-space: nowrap;
        width: $chars;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  @include text-hidden-classes;
  // // margin
  @mixin margin-classes {
    @for $i from 1 through $no-of-values {
      $margin: $i * 0.25rem;
      .margin-#{$i} {
        margin: $margin;
      }
      .margin-left-#{$i} {
        margin-left: $margin;
      }
      .margin-right-#{$i} {
        margin-right: $margin;
      }
      .margin-top-#{$i} {
        margin-top: $margin;
      }
      .margin-bottom-#{$i} {
        margin-bottom: $margin;
      }
      .margin-horizontal-#{$i} {
        margin-left: $margin;
        margin-right: $margin;
      }
      .margin-vertical-#{$i} {
        margin-top: $margin;
        margin-bottom: $margin;
      }
    }
  }
  @include margin-classes;
  // // padding
  @mixin padding-classes {
    @for $i from 1 through $no-of-values {
      $padding: $i * 0.25rem;
      .padding-#{$i} {
        padding: $padding;
      }
      .padding-left-#{$i} {
        padding-left: $padding;
      }
      .padding-right-#{$i} {
        padding-right: $padding;
      }
      .padding-top-#{$i} {
        padding-top: $padding;
      }
      .padding-bottom-#{$i} {
        padding-bottom: $padding;
      }
      .padding-horizontal-#{$i} {
        padding-left: $padding;
        padding-right: $padding;
      }
      .padding-vertical-#{$i} {
        padding-top: $padding;
        padding-bottom: $padding;
      }
    }
  }
  @include padding-classes;
  // //border-radius
  @mixin border-radius {
    @for $i from 1 through $no-of-columns {
      $radius: $i * 0.25rem;
      .border-radius-#{$i} {
        border-radius: $radius;
      }
    }
  }
  @include border-radius;
  @mixin border-classes {
    @for $i from 1 through $no-of-columns {
      $border: $i * 1px;
      .border-#{$i} {
        border: $border solid;
      }
      .border-left-#{$i} {
        border-left: $border solid;
      }
      .border-right-#{$i} {
        border-right: $border solid;
      }
      .border-top-#{$i} {
        border-top: $border solid;
      }
      .border-bottom-#{$i} {
        border-bottom: $border solid;
      }
      .border-horizontal-#{$i} {
        border-left: $border solid;
        border-right: $border solid;
      }
      .border-vertical-#{$i} {
        border-top: $border solid;
        border-bottom: $border solid;
      }
    }
  }
  @include border-classes;
  // //box-shadow
  @mixin box-shadow-classes {
    @for $i from 1 through $no-of-columns {
      .box-shadow-#{$i} {
        box-shadow: 0 0 #{$i}px #ccc6cc;
      }
    }
  }
  @include box-shadow-classes;
  .footer {
    position: absolute;
    bottom: 0;
    z-index: 9;
    width: 100%;
    margin-top: 1rem;
  }
  .bottom-end {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 16px;
    z-index: 9;
  }
  .top-end {
    .mat-fab {
      position: fixed;
      top: 0;
      right: 0;
    }
  }
}
// }
/*-----------------UTILITY STYLES ENDS--------------------------*/

html,
body {
  height: calc(100% - 35px);
}
body {
  margin: 0;
  letter-spacing: -0.1px !important;
  font-family: Mulish, sans-serif;
}

.site-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.page-content {
  padding: 0;
  height: 100%;
}

.no-margin {
  margin: 0;
}

.mt-10 {
  margin-top: 10px;
}

.overflow-x-scroll {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.pointer {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.row {
  display: flex;
}

.col-0 {
  flex: 0;
  padding: 0 5px;
}

.col,
.col-1 {
  flex: 1;
  // padding: 0 5px;
}

.col-2 {
  flex: 2;
}

.col-3 {
  flex: 3;
}

.col-4 {
  flex: 4;
}
.col-5 {
  flex: 5;
}

.p-1 {
  padding: 1rem;
}

.text-center {
  text-align: center;
}
.flex-horizontal {
  display: flex;
  flex-direction: row;
}
.vertical-super {
  vertical-align: super;
}

.vertical-center {
  display: flex;
  align-items: center;
  height: 100%;
}
//sidenav component css
.sidenav-radio-group {
  display: flex;
  flex-direction: column;
}
.sidenav-radio-button {
  margin: 5px;
}
.online {
  background-color: #7fc211;
}

.offline {
  background-color: #7b7a7a;
}

.busy {
  background-color: #c70039;
}

.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
}
.margin-left-5px {
  margin-left: 5px;
}

.full-width {
  width: 100% !important;
  max-width: 100% !important;
}
.cdk-overlay-pane.full-screen {
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  max-height: fit-content !important;
  pdf-viewer {
    max-height: 90vh !important;
  }
}
//sidenav component css end
.list-scroll {
  height: calc(100vh - 268px);
  overflow: auto;
}
.list-scroll-large {
  height: calc(100vh - 192px);
  overflow: auto;
}
.list-scroll-210 {
  height: calc(100vh - 210px);
  overflow: auto;
}
.scroll-error-320 {
  max-height: 320px;
  overflow: auto;
}
.footer-button {
  bottom: 0px;
  background: #fff;
  position: fixed;
  width: -webkit-fill-available;
  z-index: 9;
}

.footer-button-2 {
  bottom: 50px;
  background: #fff;
  z-index: 9;
  position: fixed;
  width: -webkit-fill-available;
}

.cdk-overlay-pane {
  height: auto !important;
  .mat-dialog-container {
    padding: 8px 1rem;
  }
}

//This needs to be duplicated here for the loader to show when app is starting

.loader-body {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99999999;
  height: 100%;
  text-align: center !important;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
}
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-spinner div {
  transform-origin: 32px 32px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 8px;
  left: 30px;
  width: 2px;
  height: 12px;
  border-radius: 20%;
  background: rgba(46, 117, 182, 0.8);
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.height-48px {
  height: 48px;
}

cmx-panel {
  .scroll-shipment,
  .tablepanel {
    max-height: 352px;
    overflow: auto;
  }
  .sticky-bottom {
    position: sticky;
    bottom: 0;
  }
}
cmx-panel.maximized-panel {
  .scroll-shipment {
    overflow: auto;
    max-height: calc(100vh - 155px) !important;
  }
  .cmx-card-list {
    overflow: auto;
    max-height: calc(100vh - 155px) !important;
  }
  .tablepanel {
    max-height: calc(100vh - 160px) !important;
    overflow: auto;
  }
}
cmx-panel.maximized-panel-mobile {
  .scroll-shipment {
    overflow: auto;
    max-height: calc(100vh - 155px) !important;
  }
  .cmx-card-list {
    overflow: auto;
    max-height: calc(100vh - 155px) !important;
  }
  .tablepanel {
    max-height: calc(100vh - 100px) !important;
    overflow: auto;
  }
}
.word-break {
  word-break: break-all;
}

.full-height-widget-card {
  height: calc(100vh - 150px);
}

.full-height {
  height: 100%;
}

.firebase-emulator-warning {
  bottom: -25px !important;
}
